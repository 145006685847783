// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tds-header-dropdown {
  [slot="icon"] {
    tds-badge {
      position: absolute;
      top: 18px;
      right: 27px;
    }
  }
}

.dropdown-flex{
  flex-grow: 35;
}

.header-title-container {
  display: flex;
  align-items: center;
}

.title-iot {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  padding-right: 10px;
}

.title-dashboard {
  font-size: 12px;
  font-weight: 400;
  line-height: 10px;
  text-align: left;
  padding-left: 10px;
  border-left: 1px solid #0F3263;
}
`, "",{"version":3,"sources":["webpack://./src/Layouts/Header.css"],"names":[],"mappings":"AAAA;EACE;IACE;MACE,kBAAkB;MAClB,SAAS;MACT,WAAW;IACb;EACF;AACF;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,8BAA8B;AAChC","sourcesContent":["tds-header-dropdown {\n  [slot=\"icon\"] {\n    tds-badge {\n      position: absolute;\n      top: 18px;\n      right: 27px;\n    }\n  }\n}\n\n.dropdown-flex{\n  flex-grow: 35;\n}\n\n.header-title-container {\n  display: flex;\n  align-items: center;\n}\n\n.title-iot {\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 16px;\n  letter-spacing: -0.01em;\n  text-align: left;\n  padding-right: 10px;\n}\n\n.title-dashboard {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 10px;\n  text-align: left;\n  padding-left: 10px;\n  border-left: 1px solid #0F3263;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
