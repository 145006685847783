import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  getProjectDetails,
  getProjectsByprojectCode,
  getUserDetails,
  getAllNotifications,
} from "../Services/Service.tsx";
import { Link, useLocation } from "react-router-dom";
import mqtt from "mqtt";
import "./Header.css";

const Header = ({
  isDesktop,
  mobileMenuClick,
  logoutFunc,
  session,
  username,
  selectedProjectFunc,
}) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const [projectArr, setProjectArr] = useState([]);
  const [showAvatarMenu, setShowAvatarMenu] = useState(false);
  const [openAppLauncher, setOpenAppLauncher] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const refElement = useRef(null);
  const refElement1 = useRef(null);
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [dummy, setDummy] = useState(false); // New dummy state for forcing re-render
  const [alertNotificationsArr, setAlertNotificationsArr] = useState([]);
  const [alertTodayNotificationsArr, setAlertTodayNotificationsArr] = useState(
    []
  );
  const [viewAllBoolean, setViewAllBoolean] = useState(false);
  const [client, setClient] = useState(null);
  const [isNewMessage, setIsNewMessage] = useState(false);

  useEffect(() => {
    const mqttUrl = process.env.REACT_APP_WEBHOOK_MQTT;
    const options = {
      clientId: "react-client-" + Math.random().toString(16).substr(2, 8), // Unique client ID
      username: process.env.REACT_APP_WEBHOOK_MQTT_USERNAME,
      password: process.env.REACT_APP_WEBHOOK_MQTT_PASSWORD,
      clean: true, // Clean session flag
    };
    let topic = `events/${localStorage.getItem("projectCode")}`;

    // Create a client and connect to the EMQX broker over wss://
    const mqttClient = mqtt.connect(mqttUrl, options);

    // Handle successful connection
    mqttClient.on("connect", () => {
      // Subscribe to the topic after connecting
      mqttClient.subscribe(topic, (err) => {
        if (err) {
          console.error("Error subscribing to topic:", err);
        }
      });
    });

    // Handle incoming messages
    mqttClient.on("message", (topic, message) => {
      const msg = message.toString();
      setIsNewMessage(true);
    });

    // Handle connection errors
    mqttClient.on("error", (err) => {
      console.error("Connection error:", err);
    });

    //setShowData(false);
    setClient(mqttClient);
    // Clean up and disconnect on component unmount
    return () => {
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, [localStorage.getItem("projectCode")]);

  const alertIconClickedFunc = (event) => {
    setIsNewMessage(false);
    getAlertNotificationFunc(localStorage.getItem("projectCode"));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click occurred outside the element
      if (refElement.current && !refElement.current.contains(event.target)) {
        setShowAvatarMenu(false);
      }
      if (refElement1.current && !refElement1.current.contains(event.target)) {
        setOpenAppLauncher(false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const changeLanguage = (langCode: any) => {
    localStorage.setItem("selectedLang", langCode);
    i18n.changeLanguage(langCode);
    setDummy((prev) => !prev); // Toggle dummy state to force re-render
  };

  const logout = () => {
    logoutFunc();
  };

  const openUrl = (url: any) => {
    window.location.replace(url);
    //toggleMobileNav();
  };
  const openExternalUrl = (url: any) => {
    window.open(url);
    //toggleMobileNav();
  };

  useEffect(() => {
    if (username) {
      getProjectDetailFunc();
    }
  }, [username, projectArr.length]);

  const getProjectDetailFunc = () => {
    getProjectDetails(username)
      .then(async (data) => {
        if (data.data.length > 0) {
          const finalData = await Promise.all(
            data.data.map(async (projUserData) => {
              return getProjectsByprojectCode(projUserData.projectCode)
                .then((res) => {
                  let projObj = {
                    createdBy: projUserData.createdBy,
                    createdDate: projUserData.createdDate,
                    grafanaOrgId: projUserData.grafanaOrgId,
                    grafanaUserId: projUserData.grafanaUserId,
                    projectCode: projUserData.projectCode,
                    projectUserId: projUserData.projectUserId,
                    role: projUserData.role,
                    updatedBy: projUserData.updatedBy,
                    updatedDate: projUserData.updatedDate,
                    username: projUserData.username,
                    projectName: res.data[0].projectName,
                  };
                  return projObj;
                })
                .catch((err) => {
                  console.log(err.message);
                });
            })
          );
          setProjectArr(finalData);
          if (localStorage.getItem("projectCode") == null) {
            setSelectedProjectId(finalData[0].projectUserId);
            selectedProjectFunc(finalData[0]);
            setSelectedProjectName(finalData[0].projectName);
            getAlertNotificationFunc(data.data[0].projectCode);
            localStorage.setItem("projectCode", finalData[0].projectCode);
            localStorage.setItem("projectName", finalData[0].projectName);
            localStorage.setItem(
              "projectDetails",
              JSON.stringify(finalData[0])
            );
            localStorage.setItem("projectUserId", finalData[0].projectUserId);
          } else {
            let projData = localStorage.getItem("projectDetails");
            setSelectedProjectId(localStorage.getItem("projectUserId"));
            selectedProjectFunc(JSON.parse(projData));
            setSelectedProjectName(localStorage.getItem("projectName"));
            getAlertNotificationFunc(localStorage.getItem("projectCode"));
          }
        } else {
          getUserDetails(username).then((data) => {
            if (data.data.length > 0 && data.data[0].superAdmin) {
              setProjectArr([{ projectCode: "All" }]);
              selectedProjectFunc("All");
              setSelectedProjectName("All");
            } else {
              setProjectArr([{ projectCode: "" }]);
              selectedProjectFunc("");
              setSelectedProjectName("");
            }
          });
        }
        if (pathname == "/apps/iot-service/detailAlertNotification") {
          const queryParams = new URLSearchParams(window.location.search);
          const projectCode = queryParams.get("projectCode");
          setProjectArr([{ projectCode: projectCode }]);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getAlertNotificationFunc = (projectCode) => {
    getAllNotifications(projectCode)
      .then((data) => {
        // Transform the data
        // Sort the values array
        data.data[0].values.sort((a, b) => {
          const timeA = new Date(a[0]); // Parse the first element (time) of each inner array
          const timeB = new Date(b[0]);
          return timeB - timeA; // Ascending order
        });
        const transformed = data.data.flatMap((item) =>
          item.values.map((value) => {
            const keys = item.columns;
            const transformedObject = Object.fromEntries(
              keys.map((key, index) => [
                key,
                key === "time" ? getTimeLabel(value[index]) : value[index],
              ])
            );
            return transformedObject;
          })
        );
        const todayData = transformed.filter((item) => isToday(item.time));
        if (transformed.length > 0) {
          setViewAllBoolean(true);
        } else {
          setViewAllBoolean(false);
        }
        setAlertNotificationsArr(transformed);

        setAlertTodayNotificationsArr(todayData);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const openDetailNotification = () => {
    window.open(
      `${process.env.REACT_APP_REDIRECT_IOT_SERVICE}/detailAlertNotification`,
      "_self"
    );
  };

  // Function to check if a date is today
  const isToday = (dateString) => {
    const inputDate = new Date(dateString);
    const today = new Date();
    return (
      inputDate.getDate() === today.getDate() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getFullYear() === today.getFullYear()
    );
  };

  // Filter only the data with today's time

  const getTimeLabel = (inputTimeStr) => {
    // Parse the input date string into a Date object
    const inputDate = new Date(inputTimeStr);

    // Extract the date and time components
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    // Format the date and time as required
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  return (
    <tds-header key={dummy}>
      <tds-header-hamburger
        onClick={() => {
          toggleMobileNav();
        }}
        aria-label="Open application drawer"
        aria-haspopup="true"
        aria-expanded="false"
      ></tds-header-hamburger>

      <tds-header-title>
        <div className="header-title-container">
          <Link
            onClick={() => openUrl(process.env.REACT_APP_IOT_APP_URL)}
            className="title-iot"
            style={{
              textDecoration: "none",
              color: "inherit",
              cursor: "pointer",
            }}
          >
            {t("iot-as-a-service")}
          </Link>
          <span className="title-dashboard">{t("mqtt-management")}</span>
        </div>
      </tds-header-title>

      <div className="tds-u-flex-end" slot="end">
        {projectArr.length > 0 ? (
          <tds-header-dropdown
            slot="end"
            disabled={
              pathname == "/apps/iot-service/detailAlertNotification"
                ? true
                : false
            }
          >
            <span slot="label">{selectedProjectName}</span>
            <tds-header-dropdown-list>
              {projectArr.map((project) => (
                <tds-header-dropdown-list-item key={project.projectUserId}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      selectedProjectFunc(project);
                      setSelectedProjectName(project.projectName);
                      getAlertNotificationFunc(project.projectCode);
                      localStorage.setItem("projectCode", project.projectCode);
                      localStorage.setItem(
                        "projectDetails",
                        JSON.stringify(project)
                      );
                      localStorage.setItem(
                        "projectUserId",
                        project.projectUserId
                      );
                      localStorage.setItem("projectName", project.projectName);
                    }}
                  >
                    <div className="tds-u-inline-block tds-u-pt2">
                      {project.projectName}
                    </div>
                  </a>
                </tds-header-dropdown-list-item>
              ))}
            </tds-header-dropdown-list>
          </tds-header-dropdown>
        ) : (
          ""
        )}
      </div>

      {/* alert notification  starts */}
      <tds-header-dropdown
        slot="end"
        no-dropdown-icon
        onClick={(event) => alertIconClickedFunc(event)}
      >
        <div slot="icon">
          <tds-icon size="28px" name="notification"></tds-icon>
          {isNewMessage ? <tds-badge size="sm"></tds-badge> : ""}
        </div>
        <tds-header-dropdown-list size="lg">
          <tds-header-dropdown-list-item style={{ height: "100%" }}>
            <div className=" tds-u-pt2 tds-u-pl2 tds-u-textalign-start notification-header">
              {t("notification")}
            </div>
          </tds-header-dropdown-list-item>

          {alertNotificationsArr.length > 0 ? (
            <>
              {alertTodayNotificationsArr.length > 0 ? (
                <div className="alert-notification-today tds-u-textalign-start tds-u-pt2 tds-u-pl2">
                  {t("today")}
                </div>
              ) : (
                ""
              )}
              {alertTodayNotificationsArr.map((alert, index) => {
                if (index < 4) {
                  return (
                    <tds-header-dropdown-list-item
                      style={{ height: "100%" }}
                      key={index}
                    >
                      <div className="white-background">
                        <div className="tds-u-pl2 tds-u-pt2 tds-u-pb0 tds-u-flex">
                          <div className="tds-u-flex tds-u-gap-4">
                            <div className="tds-u-flex-center">
                              {alert.priority == "critical" ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M7.4014 7.7352V6H8.5894V7.7352L8.4134 9.8824H7.5774L7.4014 7.7352ZM7.375 10.5512H8.6246V11.748H7.375V10.5512Z"
                                    fill="#FF2340"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M8 0.5C8.18653 0.5 8.35755 0.603831 8.44361 0.769324L14.9436 13.2693C15.0242 13.4243 15.0181 13.6101 14.9274 13.7595C14.8367 13.9088 14.6747 14 14.5 14H1.5C1.3253 14 1.16327 13.9088 1.0726 13.7595C0.981938 13.6101 0.975793 13.4243 1.05639 13.2693L7.55639 0.769324C7.64245 0.603831 7.81347 0.5 8 0.5ZM2.32356 13H13.6764L8 2.08377L2.32356 13Z"
                                    fill="#FF2340"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M7.99997 2.00015C4.68527 2.00015 1.99817 4.68725 1.99817 8.00195C1.99817 11.3167 4.68527 14.0038 7.99997 14.0038C11.3147 14.0038 14.0018 11.3167 14.0018 8.00195C14.0018 4.68725 11.3147 2.00015 7.99997 2.00015ZM0.999969 8.00195C0.999969 4.13596 4.13398 1.00195 7.99997 1.00195C11.866 1.00195 15 4.13596 15 8.00195C15 11.8679 11.866 15.002 7.99997 15.002C4.13398 15.002 0.999969 11.8679 0.999969 8.00195Z"
                                    fill="#F1C21B"
                                  />
                                  <path
                                    d="M7.40136 7.2352V5H8.58936V7.2352L8.41336 9.3824H7.57736L7.40136 7.2352ZM7.37496 10.0512H8.62456V11.248H7.37496V10.0512Z"
                                    fill="#F1C21B"
                                  />
                                </svg>
                              )}
                            </div>
                            <p className="alert-subject tds-u-mt0 tds-u-mb0">
                              {alert.subject}
                            </p>
                          </div>
                          {/* <div className="alerts-count float-right">1/3 alerts</div> */}
                        </div>
                        <div className="alert-notification-type-name tds-u-mt1 tds-u-mb1 tds-u-pl2 tds-u-textalign-start">
                          {alert.assetName} ID #{alert.assetId} -{" "}
                          {alert.assetTypeName}
                        </div>
                        <div className="alert-notification-message tds-u-pb1 tds-u-pl2 tds-u-textalign-start">
                          {alert.message}
                        </div>
                        {/* <div className="tds-u-mt1 tds-u-mb1 tds-u-pl2 tds-u-textalign-start">
                              <span className="alert-notification-asset-hierarchy">
                                Asset Hierarchy :
                              </span>
                              <span className="alert-notification-asset-hierarchy-data">
                                ACME/Trucks and Buses AB/Sweden/Södertälje/Line 56/Work
                                station A/
                              </span>
                              <span className="alert-notification-selected">
                                Refinery oil pump 2251
                              </span>
                            </div> */}
                        <div className="alert-notification-timestamp tds-u-textalign-start tds-u-pl2">
                          {alert.time}
                        </div>
                        <div className="tds-u-mt1 tds-u-pb1 alert-notification-divider"></div>
                      </div>
                    </tds-header-dropdown-list-item>
                  );
                }
              })}
              {viewAllBoolean ? (
                <tds-header-dropdown-list-item style={{ height: "100%" }}>
                  <div className="tds-u-p2">
                    <tds-button
                      type="button"
                      variant="primary"
                      size="sm"
                      fullbleed
                      text={t("view-all-notifications")}
                      onClick={openDetailNotification}
                    ></tds-button>
                  </div>
                </tds-header-dropdown-list-item>
              ) : (
                ""
              )}
            </>
          ) : (
            <tds-header-dropdown-list-item style={{ height: "100%" }}>
              <div className=" alert-notification-BG no-notification-message-div">
                <tds-icon name="notification_inactive" size="32px"></tds-icon>
                <p className="no-notification-message">
                  {t("no-notification-message")}
                </p>
              </div>
            </tds-header-dropdown-list-item>
          )}
        </tds-header-dropdown-list>
      </tds-header-dropdown>

      {/* alert notifications ends */}

      <tds-header-dropdown onClick={() => {}} slot="end" no-dropdown-icon>
        <div slot="icon">
          <tds-icon size="28px" name="global"></tds-icon>
        </div>
        <tds-header-dropdown-list size="md">
          <tds-header-dropdown-list-item
            style={{ cursor: "pointer" }}
            onClick={() => {
              changeLanguage("en");
            }}
          >
            <div className="tds-u-inline-block tds-u-pt2">English</div>
          </tds-header-dropdown-list-item>
          <tds-header-dropdown-list-item
            style={{ cursor: "pointer" }}
            onClick={() => {
              changeLanguage("zh");
            }}
          >
            <div className="tds-u-inline-block tds-u-pt2">简体中文</div>
          </tds-header-dropdown-list-item>
          <tds-header-dropdown-list-item
            style={{ cursor: "pointer" }}
            onClick={() => {
              changeLanguage("pt");
            }}
          >
            <div className="tds-u-inline-block tds-u-pt2">Português</div>
          </tds-header-dropdown-list-item>
        </tds-header-dropdown-list>
      </tds-header-dropdown>

      <tds-header-launcher slot="end" aria-label="Application launcher">
        <tds-header-launcher-grid-title>
          {t("iot-services")}
        </tds-header-launcher-grid-title>
        <tds-header-launcher-grid>
          <tds-header-launcher-grid-item>
            <Link onClick={() => openUrl(process.env.REACT_APP_MQTT_URL)}>
              <tds-icon name="filters" size="32"></tds-icon>
              {t("mqtt-manager")}
            </Link>
          </tds-header-launcher-grid-item>
          <tds-header-launcher-grid-item>
            <Link
              onClick={() =>
                openUrl(process.env.REACT_APP_ASSET_MANAGEMENT_URL)
              }
            >
              <tds-icon name="folder" size="32"></tds-icon>
              {t("asset-modelling")}
            </Link>
          </tds-header-launcher-grid-item>
          <tds-header-launcher-grid-item>
            <Link
              onClick={() => openUrl(process.env.REACT_APP_SELF_SERVICE_URL)}
            >
              <tds-icon name="report" size="32"></tds-icon>
              {t("dashboards")}
            </Link>
          </tds-header-launcher-grid-item>
        </tds-header-launcher-grid>
        <tds-header-launcher-grid-title>
          {t("external-services")}
        </tds-header-launcher-grid-title>
        <tds-header-launcher-grid>
          <tds-header-launcher-grid-item>
            <Link
              onClick={() =>
                openExternalUrl(process.env.REACT_APP_INFLUXDB_URL)
              }
            >
              <tds-icon name="redirect" size="32"></tds-icon>
              {t("influxdb")}
            </Link>
          </tds-header-launcher-grid-item>
          <tds-header-launcher-grid-item>
            <Link
              onClick={() => openExternalUrl(process.env.REACT_APP_GRAFANA_URL)}
            >
              <tds-icon name="redirect" size="32"></tds-icon>
              {t("grafana")}
            </Link>
          </tds-header-launcher-grid-item>
          <tds-header-launcher-grid-item>
            <Link
              onClick={() => openExternalUrl(process.env.REACT_APP_EMQX_URL)}
            >
              <tds-icon name="redirect" size="32"></tds-icon>
              {t("emqx")}
            </Link>
          </tds-header-launcher-grid-item>
        </tds-header-launcher-grid>
      </tds-header-launcher>

      <tds-header-dropdown onClick={() => {}} slot="end" no-dropdown-icon>
        <div slot="icon">
          <img
            src="https://www.svgrepo.com/show/384676/account-avatar-profile-user-6.svg"
            alt="User menu."
          />
        </div>
        <tds-header-dropdown-list size="lg">
          <tds-header-dropdown-list-user
            header={username}
          ></tds-header-dropdown-list-user>
          <tds-header-dropdown-list-item onClick={logoutFunc}>
            <Link href="#" passHref>
              <div style={{ display: "inline-block" }}>
                <tds-icon
                  name="profile_inactive"
                  style={{ display: "inline-block" }}
                ></tds-icon>
                <div className="tds-u-pl1" style={{ display: "inline-block" }}>
                  {t("logout")}
                </div>
              </div>
            </Link>
          </tds-header-dropdown-list-item>
        </tds-header-dropdown-list>
      </tds-header-dropdown>

      <tds-header-brand-symbol slot="end">
        <a aria-label="Scania - red gryphon on blue shield"></a>
      </tds-header-brand-symbol>
    </tds-header>
  );
};

export default Header;
