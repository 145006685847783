import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Accordion from "./Accordion.tsx";
import { LoggedInContext } from "../../Context/Context.tsx";
import {
  getAllProjects,
  getAllTopics,
  getProjectsByprojectCode,
  getTopicsByProjectId,
  enableMetricsByTopicName,
  disableMetricsByTopicName,
  getAllTopicMetrics
} from "../../Services/Service.tsx";
import mqtt, { MqttClient } from "mqtt";

const MessageExplorer = () => {
  const context = useContext(LoggedInContext);
  const [projWarning, setProjWarning] = useState(false);
  const [projectArr, setProjectArr] = useState([]);
  const emptyStructuredArr = [
    { topicName: "", expanded: false, children: [], fullTopicName: "" },
  ];
  const [topicArr, setTopicArr] = useState(emptyStructuredArr);
  const { t } = useTranslation();
  const [selectedTopicName, setSelectedTopicName] = useState("");
  const [selectedTopicNameFull, setSelectedTopicNameFull] = useState("");
  const [messagePayload, setMessagePayload] = useState("");
  const [messageQos, setMessageQos] = useState("");
  const [messageTime, setMessageTime] = useState("");
  const [selectedTopicArray, setSelectedTopicArray] = useState([]);
  const [messageObj, setMessageObj] = useState({});
  const [showCopySuccessToolTip, setShowCopySuccessToolTip] = useState(false);
  const [showCopyValueToolTip, setShowCopyValueToolTip] = useState(false);
  const [showCopyHistoryToolTip, setShowCopyHistoryToolTip] = useState(false);
  const [isJsonFormat, setIsJsonFormat] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showMetrics, setShowMetrics] = useState();
  const [topicMetrics, setTopicMetrics] = useState({});
  const [toggleChange, setToggleChange] = useState(0);

  useEffect(() => {
    getAllProjectsFunc();
  }, [context.session.projectCode]);

  useEffect(() => {
    if(showMetrics == true && showMetrics != undefined) {
      const topicMetrics = {
        "topic": selectedTopicNameFull
      };
      //call post api for metrics
      enableMetricsByTopicName(topicMetrics)
          .then((data) => {
            setTopicMetrics(data.data);
            return data;
          })
          .catch((err) => {
            console.log(err.message);
          });
    } else if(!showMetrics && showMetrics != undefined){
      //call delete api for metrics
      if(selectedTopicNameFull != "") {
        disableMetricsByTopicName(selectedTopicNameFull)
        .then((data) => {
        console.log("metrics deleted");
        return data;
      })
      }
     }
  },[toggleChange]);

  useEffect(() => {
    getTopicMetricsByTopicName(selectedTopicNameFull);
    setTopicMetrics({});
  }, [selectedTopicNameFull]);

  const getTopicMetricsByTopicName = (topicName) => {
    getAllTopicMetrics()
        .then((data: any) => {
          if(data.data.length > 0) {
            data.data.forEach(function(topicMetric) {
              if(topicMetric.topic == topicName) {
                setTopicMetrics(topicMetric);
                setShowMetrics(true);
              } 
            });
          }
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    };

  // API call to get all projects
  const getAllProjectsFunc = () => {
    if (context.session.projectCode == "All") {
      getAllProjects()
        .then((data: any) => {
          let projectCheck = data.data.length > 0 ? false : true;
          setProjWarning(projectCheck);
          getAllTopicsFunc();
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else {
      getProjectsByprojectCode(context.session.projectCode)
        .then((data: any) => {
          let projectCheck = data.data.length > 0 ? false : true;
          setProjWarning(projectCheck);
          setProjectArr(data.data);
          getAllTopicsFunc(data.data[0].projectId);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };


  // API call to get all topics
  const getAllTopicsFunc = (projectId = "") => {
    const projectCode = context.session.projectCode;
    if (projectCode == "All") {
      getAllTopics()
        .then((data: any) => {
          setTopicHierarchy(data);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else if (projectCode != "" && projectCode != null) {
      getTopicsByProjectId(projectId)
        .then(async (data: any) => {
          setTopicHierarchy(data);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };

  const setTopicHierarchy = async (data) => {
    let finalStructuredList = [];
    for (let res of data.data) {
      let dataObj = {};
      let parentChild = res.topicName.split("/");
      //checking if the same parent exists
      const foundItem = finalStructuredList.find(
        (item) => item.topicName === parentChild[0]
      );
      if (foundItem == undefined) {
        dataObj["fullTopicName"] = res.topicName;
        dataObj["topicName"] = parentChild[0];
        dataObj["expanded"] = true;
        dataObj["isDirectory"] = true;
        if (parentChild.length > 1) {
          let child = await getChildren(
            parentChild,
            1,
            foundItem,
            res.topicName
          );
          dataObj["children"] = child;
          finalStructuredList.push(dataObj);
        } else {
          dataObj["children"] = [];
          finalStructuredList.push(dataObj);
        }
      } else {
        let child = await getChildren(parentChild, 1, foundItem, res.topicName);
      }
    }
    setTopicArr([...finalStructuredList]);
  };

  //recurrsive array to set the children
  const getChildren = async (parentChild, index, sameParent, fullTopic) => {
    let childArr = [];
    let childObj = {};
    childObj["fullTopicName"] = fullTopic;
    childObj["topicName"] = parentChild[index];
    childObj["expanded"] = true;
    childObj["isDirectory"] = false;
    if (index < parentChild.slice(1).length) {
      let child = await getChildren(parentChild, ++index, undefined, fullTopic);
      childObj["children"] = child;
    } else {
      childObj["children"] = [];
    }
    childArr.push(childObj);
    //condition added to check if the data has same parent then the children are updated
    if (sameParent) {
      sameParent["children"] = [...sameParent["children"], ...childArr];
    }
    return childArr;
  };

  const getSelectedTopicName = (topic) => {
    setShowMetrics(false);
    setSelectedTopicNameFull(topic);
    const selectedTopic = topic.split("/");
    setSelectedTopicName(selectedTopic[selectedTopic.length - 1]);
    setSelectedTopicArray(selectedTopic);
  };

  

  const copyTopicFunc = () => {
    navigator.clipboard
      .writeText(selectedTopicArray.join("/"))
      .then(() => {
        setShowCopySuccessToolTip(true);
        setTimeout(() => {
          setShowCopySuccessToolTip(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const copyValueFunc = () => {
    navigator.clipboard
      .writeText(messagePayload)
      .then(() => {
        setShowCopyValueToolTip(true);
        setTimeout(() => {
          setShowCopyValueToolTip(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const copyHistoryFunc = (payload) => {
    navigator.clipboard
      .writeText(JSON.stringify(payload))
      .then(() => {
        setShowCopyHistoryToolTip(true);
        setTimeout(() => {
          setShowCopyHistoryToolTip(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const copyHistoryArr = () => {
    navigator.clipboard
      .writeText(JSON.stringify(messageObj[selectedTopicNameFull]))
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const getPublishedMessage = (message, qos, time) => {
    setMessagePayload(message);
    setMessageQos(qos);

    const date = new Date(time);
    // Format the date and time
    const formattedTime = date
      .toLocaleTimeString("en-GB", { hour12: false })
      .padStart(8, "0"); // HH:mm:ss format
    const formattedDate = date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .split("/")
      .join("."); // DD.MM.YYYY format
    setMessageTime(formattedTime + " - " + formattedDate);

    const newMessage = { message: message, time: time };
    //To update the new message for the selected topic in the message object
    //(prev[selectedTopicNameFull] || []) this syntax means that the prev data to the selected topic is added and if not data is there assign it as empty array
    setMessageObj((prev) => ({
      ...prev,
      [selectedTopicNameFull]: [
        ...(prev[selectedTopicNameFull] || []),
        newMessage,
      ],
    }));
    //call getMetricsByTopicName
    getTopicMetricsByTopicName(selectedTopicNameFull);
  };

  const selectedOpt = (jsonFormat: any) => {
    setIsJsonFormat(jsonFormat);
  };

  const convertDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const formattedTime = date
      .toLocaleTimeString("en-GB", { hour12: false })
      .padStart(8, "0"); // HH:mm:ss format
    const formattedDate = date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .split("/")
      .reverse()
      .join("-"); // DD.MM.YYYY format
    return formattedDate + " " + formattedTime;
  };

  const expandHistory = () => {
    setIsExpanded(!isExpanded);
  };

  const resetTopic = () => {
    setMessagePayload("");
    setMessageQos("");
    setMessageTime("");
  };

  const handleToggleChange = (event: any) => {
    const isChecked = event.detail.checked; 
    setShowMetrics(isChecked);
    setToggleChange(toggleChange => toggleChange +1)
  };

  return (
    <>
      {projWarning ? (
        <div>
          <tds-message
            variant="warning"
            header={t("no-project-assigned-info")}
          ></tds-message>
        </div>
      ) : (
        ""
      )}
      <div className="tds-u-flex tds-u-p0 tds-u-flex-dir-row gap10 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
        <div className="tds-u-flex tds-u-flex-dir-col tds-u-p0 gap10 tds-col-max-5 tds-col-xxlg-5 tds-col-xlg-5 tds-col-lg-5 tds-col-md-5 tds-col-sm-6 tds-col-xs-7">
          {/* Hierarchy */}
          <div className="page-background-white hierarchy-css flexGrow">
            <div className="tree-hierarchy">
              <div>
                <Accordion
                  data={topicArr}
                  getSelectedTopicName={getSelectedTopicName}
                  getPublishedMessage={getPublishedMessage}
                  resetTopic={resetTopic}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="tds-u-flex tds-u-flex-dir-col tds-u-pl1 tds-u-pr0 gap10 tds-col-max-7 tds-col-xxlg-7 tds-col-xlg-7 tds-col-lg-7 tds-col-md-7 tds-col-sm-6 tds-col-xs-5">
          {/* Topic */}
          <div className="page-background-white tds-u-p2">
            <div className="selectedTopicName">
              {selectedTopicName}
              <span
                className="tds-u-ml1 cursor-pointer"
                onClick={() => copyTopicFunc()}
              >
                <tds-icon name="copy" size="20px"></tds-icon>
              </span>
              <tds-tooltip
                placement="right-end"
                text={t("copy-topic")}
                selector="#copy-tooltip"
                offset-distance="8"
                offset-skidding="8"
                mouse-over-tooltip="true"
                show={showCopySuccessToolTip}
              ></tds-tooltip>
            </div>
            <div className="selectedTopicChipDiv ">
              {selectedTopicArray.map((topic, index) =>
                index != selectedTopicArray.length - 1 ? (
                  <>
                    <tds-chip
                      id="selectedTopicChipParent"
                      type="button"
                      size="sm"
                    >
                      <span slot="label">{topic}</span>
                    </tds-chip>
                    <span className="selectedTopicChipParentSeperator">/</span>
                  </>
                ) : (
                  <tds-chip id="selectedTopicChip" type="button" size="sm">
                    <span slot="label">{topic}</span>
                  </tds-chip>
                )
              )}
            </div>
          </div>
          {/* metrics content */}
          {selectedTopicNameFull != "" ? 
          (<div className="page-background-white tds-u-p2">
            <div className="selectedTopicName tds-u-flex">
              <div>Topic Statistics</div>
              <div className="tds-u-pl2">
                <tds-toggle
                  size="md"
                  checked={showMetrics}
                  ref={(element) =>
                    element?.addEventListener("tdsToggle", (event) => {
                      handleToggleChange(event)
                  },{once:true})
                  }
                >
                  <div slot="label">{showMetrics ? "On" : "Off"}</div>
                </tds-toggle>
              </div>
            </div>
            {showMetrics ? (
              <div className="tds-u-flex tds-u-pt2">
                <div className="tds-u-p0 tds-u-pr2  tds-col-max-4 tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-6 tds-col-xs-5">
                  <div className="box-color-grey tds-u-pr2 tds-u-pl2 tds-u-pt12 tds-u-pb12">
                    <div className="tds-u-flex ">
                      <div className="metrics-div-count">{topicMetrics.messageIn}</div>
                      <div className="metrics-div-type">{t("incoming")}</div>
                      <div className="metrics-div-info-icon">
                        <tds-icon
                          name="info"
                          size="16px"
                          id="incomingTooltip"
                        ></tds-icon>
                        <tds-tooltip
                          placement="top"
                          text={t("incoming-tooltip")}
                          selector="#incomingTooltip"
                        />
                      </div>
                    </div>
                    <div className="metrics-div-datetime">{topicMetrics.time}</div>
                  </div>
                </div>
                <div className="tds-u-p0 tds-u-pr2  tds-col-max-4 tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-6 tds-col-xs-5">
                  <div className="box-color-grey tds-u-pr2 tds-u-pl2 tds-u-pt12 tds-u-pb12">
                    <div className="tds-u-flex ">
                      <div className="metrics-div-count">{topicMetrics.messageOut}</div>
                      <div className="metrics-div-type">{t("outgoing")}</div>
                      <div className="metrics-div-info-icon">
                        <tds-icon
                          name="info"
                          size="16px"
                          id="outgoingTooltip"
                        ></tds-icon>
                        <tds-tooltip
                          placement="top"
                          text={t("outgoing-tooltip")}
                          selector="#outgoingTooltip"
                        />
                      </div>
                    </div>
                    <div className="metrics-div-datetime">{topicMetrics.time}</div>
                  </div>
                </div>
                <div className="tds-u-p0 tds-col-max-4 tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-6 tds-col-xs-5">
                  <div className="box-color-grey tds-u-pr2 tds-u-pl2 tds-u-pt12 tds-u-pb12">
                    <div className="tds-u-flex ">
                      <div className="metrics-div-count">{topicMetrics.messageDropped}</div>
                      <div className="metrics-div-type">{t("dropped")}</div>
                      <div className="metrics-div-info-icon">
                        <tds-icon
                          name="info"
                          size="16px"
                          id="droppedTooltip"
                        ></tds-icon>
                        <tds-tooltip
                          placement="top"
                          text={t("dropped-tooltip")}
                          selector="#droppedTooltip"
                        />
                      </div>
                    </div>
                    <div className="metrics-div-datetime">{topicMetrics.time}</div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>) : 
          ""
          }
          {/* Value and History */}
          <div>
            {/* Value */}
            <div className="page-background-white borderBottom">
              {/* Value Heading */}
              <div className="explorer-value">
                <div className="explorer-value-text">{t("value")}</div>
                <div>
                  <span
                    className="tds-u-ml1 cursor-pointer"
                    onClick={() => copyValueFunc()}
                  >
                    <tds-icon name="copy" size="20px"></tds-icon>
                  </span>
                  <tds-tooltip
                    placement="right-end"
                    text={t("value-copy")}
                    selector="#copy-value-tooltip"
                    offset-distance="8"
                    offset-skidding="8"
                    mouse-over-tooltip="true"
                    show={showCopyValueToolTip}
                  ></tds-tooltip>
                </div>
              </div>
              {/* Value button and QoS and datetime */}
              <div className="tds-u-flex tds-u-flex-dir-col explorer-value-payload-area">
                <div className="tds-u-flex tds-u-justify-between tds-u-pr2">
                  <div className="tds-u-flex tds-u-align-start explorer-value-sub p4">
                    <div
                      className={
                        !isJsonFormat
                          ? "selectedBackground plpr12ptpb4"
                          : "plpr12ptpb4"
                      }
                      onClick={() => selectedOpt(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M11.5 3.85014C11.2559 3.60607 11.2559 3.21034 11.5 2.96626C11.7441 2.72218 12.1398 2.72218 12.3839 2.96626L18.274 8.85628C18.9087 9.49088 18.9086 10.5198 18.274 11.1544L12.3839 17.0446C12.1398 17.2887 11.7441 17.2887 11.5 17.0446C11.2559 16.8005 11.2559 16.4048 11.5 16.1607L17.0304 10.6303C17.4814 10.1792 17.4814 9.83144 17.0303 9.38026L11.5 3.85014Z"
                          fill="#0D0F13"
                        />
                        <path
                          d="M8.5 3.85014C8.74408 3.60607 8.74408 3.21034 8.5 2.96626C8.25592 2.72218 7.86019 2.72218 7.61612 2.96626L1.72595 8.85628C1.09135 9.49088 1.09135 10.5198 1.72595 11.1544L7.61612 17.0446C7.86019 17.2887 8.25592 17.2887 8.5 17.0446C8.74408 16.8005 8.74408 16.4048 8.5 16.1607L2.96961 10.6303C2.51855 10.1792 2.51855 9.83144 2.96973 9.38026L8.5 3.85014Z"
                          fill="#0D0F13"
                        />
                      </svg>
                    </div>
                    <div
                      className={
                        isJsonFormat
                          ? "selectedBackground plpr12ptpb4"
                          : "plpr12ptpb4"
                      }
                      onClick={() => selectedOpt(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.625 5.125C16.625 5.47018 16.3452 5.75 16 5.75L4 5.75C3.65482 5.75 3.375 5.47018 3.375 5.125C3.375 4.77982 3.65482 4.5 4 4.5L16 4.5C16.3452 4.5 16.625 4.77982 16.625 5.125Z"
                          fill="#0D0F13"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.625 8.375C16.625 8.72018 16.3452 9 16 9L4 9C3.65482 9 3.375 8.72018 3.375 8.375C3.375 8.02982 3.65482 7.75 4 7.75L16 7.75C16.3452 7.75 16.625 8.02982 16.625 8.375Z"
                          fill="#0D0F13"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.625 11.625C16.625 11.9702 16.3452 12.25 16 12.25L4 12.25C3.65482 12.25 3.375 11.9702 3.375 11.625C3.375 11.2798 3.65482 11 4 11L16 11C16.3452 11 16.625 11.2798 16.625 11.625Z"
                          fill="#0D0F13"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10.625 14.875C10.625 15.2202 10.3452 15.5 10 15.5H4C3.65482 15.5 3.375 15.2202 3.375 14.875C3.375 14.5298 3.65482 14.25 4 14.25H10C10.3452 14.25 10.625 14.5298 10.625 14.875Z"
                          fill="#0D0F13"
                        />
                      </svg>
                    </div>
                  </div>
                  <div>
                    <div className="tds-u-flex">
                      <div className="metrics-div-info-icon">
                        <tds-icon
                          name="info"
                          size="16px"
                          id="qosTooltip"
                        ></tds-icon>
                        <tds-tooltip
                          placement="left"
                          text={t("qos-tooltip")}
                          selector="#qosTooltip"
                        >
                          <p className="tds-u-p0 tds-u-m0 tds-u-textalign-start">
                            {t("qos-0")}
                          </p>
                          <p className="tds-u-p0 tds-u-m0 tds-u-textalign-start">
                            {t("qos-1")}
                          </p>
                          <p className="tds-u-p0 tds-u-m0 tds-u-textalign-start">
                            {t("qos-2")}
                          </p>
                        </tds-tooltip>
                      </div>
                      <div className="explore-value-qos">
                        {t("qos")}: {messageQos}
                      </div>
                    </div>
                    <div className="explore-value-time">{messageTime}</div>
                  </div>
                </div>
                <div className="tds-u-pr0 tds-u-pl0 tds-u-align-start tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                  <tds-textarea
                    state="default"
                    label=""
                    helper=""
                    name="valueTemplate"
                    value={messagePayload}
                    rows="15"
                    no-min-width
                  ></tds-textarea>
                </div>
              </div>
            </div>
            {/* History */}
            <div className="page-background-white">
              <div className="tds-u-flex tds-u-justify-between tds-u-p2">
                <div className="explorer-value-text">{t("history")}</div>
                <div className="tds-u-flex">
                  <tds-button
                    size="xs"
                    variant="secondary"
                    text={t("copy-all")}
                    onClick={() => copyHistoryArr()}
                  ></tds-button>
                  <div className="pl10" onClick={() => expandHistory()}>
                    {isExpanded ? (
                      <tds-icon name="chevron_up" size="16px"></tds-icon>
                    ) : (
                      <tds-icon name="chevron_down" size="16px"></tds-icon>
                    )}
                  </div>
                </div>
              </div>
              {isExpanded ? (
                <div className="tds-u-p2 history-align">
                  {messageObj.hasOwnProperty(selectedTopicNameFull) &&
                    messageObj[selectedTopicNameFull].map((payload) => (
                      <div className="explore-history tds-u-mb1">
                        <div className="tds-u-flex tds-u-justify-between">
                          <div className="explore-history-datetime">
                            {convertDateTime(payload.time)}
                          </div>
                          <div>
                            <div
                              className="tds-u-ml1 cursor-pointer"
                              onClick={() => copyHistoryFunc(payload)}
                            >
                              <tds-icon name="copy" size="16px"></tds-icon>
                            </div>
                            <tds-tooltip
                              placement="right-end"
                              text={t("copy")}
                              selector="#copy-value-tooltip"
                              offset-distance="8"
                              offset-skidding="8"
                              mouse-over-tooltip="true"
                              show={showCopyHistoryToolTip}
                            ></tds-tooltip>
                          </div>
                        </div>
                        <div className="explore-history-message tds-u-flex-start">
                          {payload.message}
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageExplorer;
