import mqtt from "mqtt";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const AccordionItem = ({
  item,
  children,
  level = 0,
  index,
  parent,
  getSelectedTopicName,
  getPublishedMessage,
  resetTopic,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showData, setShowData] = useState(false);
  const [selectedTopicName, setSelectedTopicName] = useState("");
  const [connected, setConnected] = useState(false);
  const [client, setClient] = useState(null);
  const { t } = useTranslation();

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const selectedTopic = (fullTopicName) => {
    resetTopic();
    setSelectedTopicName(fullTopicName);
    setShowData(true);
    getSelectedTopicName(fullTopicName);
  };

  useEffect(() => {
    const mqttUrl = process.env.REACT_APP_WEBHOOK_MQTT;
    const options = {
      clientId: "react-client-" + Math.random().toString(16).substr(2, 8), // Unique client ID
      username: process.env.REACT_APP_WEBHOOK_MQTT_USERNAME,
      password: process.env.REACT_APP_WEBHOOK_MQTT_PASSWORD,
      clean: true, // Clean session flag
    };

    if (showData) {
      // Create a client and connect to the EMQX broker over wss://
      const mqttClient = mqtt.connect(mqttUrl, options);

      // Handle successful connection
      mqttClient.on("connect", () => {
        setConnected(true);
        // Subscribe to the topic after connecting
        mqttClient.subscribe(selectedTopicName, (err) => {
          if (err) {
            console.error("Error subscribing to topic:", err);
          }
        });
      });

      // Handle incoming messages
      mqttClient.on("message", (selectedTopicName, message, packet) => {
        const msg = message.toString();
        const time = new Date().toLocaleString();
        getPublishedMessage(msg, packet.qos, time);
      });

      // Handle connection errors
      mqttClient.on("error", (err) => {
        console.error("Connection error:", err);
      });

      //setShowData(false);
      setClient(mqttClient);
      // Clean up and disconnect on component unmount
      return () => {
        if (mqttClient) {
          mqttClient.end();
        }
      };
    }
  }, [showData]);

  return (
    <li className="asset-hierarchy-li">
      <div
        className={`accordion-item ${isOpen ? "open" : ""}`}
        style={{ paddingLeft: `${level * 10}px` }}
      >
        {item.children.length != 0 ? (
          <>
            <span className="arrow" onClick={toggleAccordion}>
              {isOpen ? (
                <tds-icon
                  name="chevron_up"
                  size="16px"
                  style={{ color: "#0D0F13" }}
                ></tds-icon>
              ) : (
                <tds-icon
                  name="chevron_down"
                  size="16px"
                  style={{ color: "#0D0F13" }}
                ></tds-icon>
              )}
            </span>
            <span
              onClick={toggleAccordion}
              className={`header-text ${
                item.isDirectory ? "directory-title" : ""
              }`}
            >
              {item.topicName}
            </span>
          </>
        ) : (
          <>
            <span className="asset-hierarchy-isAsset-dot">•</span>
            <span
              className="asset-hierarchy-isAsset"
              onClick={() => selectedTopic(item.fullTopicName)}
            >
              {item.topicName}
            </span>
          </>
        )}
      </div>
      {isOpen && children && <ul className="nested">{children}</ul>}
    </li>
  );
};

const Accordion = ({
  data,
  getSelectedTopicName,
  getPublishedMessage,
  resetTopic,
  level = 1,
}) => {
  const renderAccordionItems = (items, currentLevel, parent = "") => {
    return items.map((item, index) => (
      <AccordionItem
        key={index}
        item={item}
        level={currentLevel}
        index={index}
        parent={parent}
        getSelectedTopicName={getSelectedTopicName}
        getPublishedMessage={getPublishedMessage}
        resetTopic={resetTopic}
      >
        {item.children &&
          renderAccordionItems(item.children, currentLevel + 1, item)}
      </AccordionItem>
    ));
  };

  return <ul className="accordion">{renderAccordionItems(data, level)}</ul>;
};

export default Accordion;
