import axios from "axios";
import eventEmitter from "../EventEmitter.tsx";

const api = () => {
  const token = localStorage.getItem(
    "msal." + process.env.REACT_APP_AD_CLIENT_ID + ".idtoken"
  );
  const config = {
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { Authorization: `Bearer ${token}` },
  };
  const instance = axios.create(config);

  // Set up loading state
  let loading = false;
  // Request interceptor
  instance.interceptors.request.use(
    (requestConfig) => {
      // Set loading to true when a request is initiated
      eventEmitter.emit("loader", "true");
      loading = true;
      return requestConfig;
    },
    (error) => {
      // Set loading to false if there's an error in the request
      eventEmitter.emit("loader", "false");
      loading = false;
      return Promise.reject(error);
    }
  );

  // Response interceptor
  instance.interceptors.response.use(
    (response) => {
      // Successful responses
      eventEmitter.emit("loader", "false");
      loading = false;
      return response;
    },
    (error) => {
      eventEmitter.emit("loader", "false");
      loading = false;
      // Handle errors globally here
      if (error?.response?.status == 401) {
        // The request was made, and the server responded with a status code
        localStorage.clear();
        window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
        // You can access other information in error.response.data if needed
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error setting up the request", error.message);
      }

      return Promise.reject(error);
    }
  );

  const isLoading = () => loading;

  return { instance, isLoading };
};

const api_rule = () => {
  const token = localStorage.getItem(
    "msal." + process.env.REACT_APP_AD_CLIENT_ID + ".idtoken"
  );
  const config = {
    baseURL: process.env.REACT_APP_RULE_URL,
    headers: { Authorization: `Bearer ${token}` },
  };
  const instance = axios.create(config);

  // Set up loading state
  let loading = false;
  // Request interceptor
  instance.interceptors.request.use(
    (requestConfig) => {
      // Set loading to true when a request is initiated
      eventEmitter.emit("loader", "true");
      loading = true;
      return requestConfig;
    },
    (error) => {
      // Set loading to false if there's an error in the request
      eventEmitter.emit("loader", "false");
      loading = false;
      return Promise.reject(error);
    }
  );

  // Response interceptor
  instance.interceptors.response.use(
    (response) => {
      // Successful responses
      eventEmitter.emit("loader", "false");
      loading = false;
      return response;
    },
    (error) => {
      eventEmitter.emit("loader", "false");
      loading = false;
      // Handle errors globally here
      if (error?.response?.status == 401) {
        // The request was made, and the server responded with a status code
        localStorage.clear();
        window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
        // You can access other information in error.response.data if needed
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error setting up the request", error.message);
      }

      return Promise.reject(error);
    }
  );

  const isLoading = () => loading;

  return { instance, isLoading };
};

export const getAllRoles = () => api().instance.get("/role");
export const updateRole = (data: any) => api().instance.put("/role", data);
export const createRole = (data: any) => api().instance.post("/role", data);
export const actDeactRole = (roleId: any) =>
  api().instance.put(`/role/${roleId}`);
export const deleteRole = (roleId: any) =>
  api().instance.delete(`/role/${roleId}`);

// projects
export const getAllProjects = () => api_rule().instance.get(`/project`);
export const getProjectsByprojectCode = (projectCode: any) =>
  api_rule().instance.get(`/project?projectCode=${projectCode}`);
export const getByIdProjects = (projectId: any) =>
  api().instance.get(`/projects?projectId=${projectId}`);
export const updateProject = (data: any) =>
  api().instance.put("/projects", data);
export const createProject = (data: any) =>
  api().instance.post("/projects", data);
export const actDeactProject = (projectId: any) =>
  api().instance.put(`/projects/${projectId}`);
export const deleteProject = (projectId: any) =>
  api().instance.delete(`/projects/${projectId}`);
export const getProjectDetails = (username: any) =>
  api_rule().instance.get(`/projectUser?username=${username}`);
export const getUserDetails = (username: any) =>
  api_rule().instance.get(`/user?username=${username}`);

//Topic
export const getAllTopics = () => api().instance.get("/topics");
export const getTopicsByProjectId = (projectId) =>
  api().instance.get(`/topics?projectId=${projectId}`);
export const updateTopic = (data: any) => api().instance.put("/topics", data);
export const createTopic = (data: any) => api().instance.post("/topics", data);
export const actDeactTopic = (topicId: any) =>
  api().instance.put(`/topics/${topicId}`);
export const deleteTopic = (topicId: any) =>
  api().instance.delete(`/topics/${topicId}`);

//Client
export const getAllClients = () => api().instance.get("/clients");
export const getClientsByTopicId = (topicId) =>
  api().instance.get(`/clients?topicId=${topicId}`);
export const getClientsByProjectId = (projectId) =>
  api().instance.get(`/clients?projectId=${projectId}`);
export const updateClient = (data: any) => api().instance.put("/clients", data);
export const createClient = (data: any) =>
  api().instance.post("/clients", data);
export const actDeactClient = (clientId: any) =>
  api().instance.put(`/clients/${clientId}`);
export const deleteClient = (clientId: any) =>
  api().instance.delete(`/clients/${clientId}`);

//Metrics
export const enableMetricsByTopicName = (data: any) =>
  api_rule().instance.post(`/metrics`, data);
export const disableMetricsByTopicName = (topicName: any) =>
  api_rule().instance.delete(`/metrics?topicName=${topicName}`);
export const getAllTopicMetrics = () => 
  api_rule().instance.get(`/metrics`);

//Control center user
export const getAllControls = () => api().instance.get("/ccusers");
export const getByIdControls = (username: any) =>
  api().instance.get(`/ccusers?username=${username}`);
export const updateControl = (data: any) =>
  api().instance.put("/ccusers", data);
export const createControl = (data: any) =>
  api().instance.post("/ccusers", data);
export const actDeactControl = (id: any, projectId: any) =>
  api().instance.put(`/ccusers/${id}/${projectId}`);
export const deleteControl = (id: any, projectId: any) =>
  api().instance.delete(`/ccusers/${id}/${projectId}`);

// schema registry
export const getAllSchemas = () => api().instance.get("/schemaregistry");
export const createSchema = (data: any) =>
  api().instance.post("/schemaregistry", data);
export const updateSchema = (data: any) =>
  api().instance.put("/schemaregistry", data);
export const deleteSchema = (id: any) =>
  api().instance.delete(`/schemaregistry/${id}`);

// Rule Engine
export const getAllRules = () => api_rule().instance.get(`/ruleEngine`);
export const getRulesByProjectCode = (note: any) =>
  api_rule().instance.get(`/ruleEngine?note=${note}`);
export const deleteRuleById = (ruleId: any) =>
  api_rule().instance.delete(`/ruleEngine/${ruleId}`);
export const createRuleApi = (data: any) =>
  api_rule().instance.post("/ruleEngine", data);
export const updateRuleApi = (data: any) =>
  api_rule().instance.put("/ruleEngine", data);
export const getActionData = (actionName: any) =>
  api_rule().instance.get(`/ruleEngine/action?actionName=${actionName}`);
export const createActionApi = (data: any) =>
  api_rule().instance.post("/ruleEngine/action", data);
export const updateActionApi = (data: any) =>
  api_rule().instance.put("/ruleEngine/action", data);

// Banner Message Services
export const getAllBannerMessages = () =>
  api_rule().instance.get("/bannerMessage");

//Notification
export const getAllNotifications = (projectCode) =>
  api_rule().instance.get(`/notification/events/${projectCode}`);
